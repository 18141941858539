@import "../../global.scss";

.works {
  // background-color: crimson;
  background-color:white;


  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .arrow {
    height: 50px;
    color: red; //
   background-color:green;
    position: absolute;
    cursor: pointer;
   

    @include mobile {
      display: none;
    }

    &.left {
      left: 100px;
      transform: rotateY(180deg);
    }

    &.right {
      right: 100px;
    }
  }

  .slider {
    height: 350px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;
    

    @include mobile {
      height: 100vh;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 700px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 80%;
          height: 150px;
          margin: 15px 0;
        }

        .left {
          flex: 4;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .imgContainer {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: rgb(245, 179, 155);
              display: flex;
              align-items: center;
              justify-content: center;

              @include mobile {
                width: 20px;
                height: 20px;
              }

              img {
                width: 25px;

                @include mobile {
                  width: 15px;
                }
              }
            }
            h2 {
              font-size: 20px;

              @include mobile{
                font-size: 13px;
              }
            }
            p {
              font-size: 13px;
              @include mobile{
                display: none;
              }
            }
            span {
              font-size: 12px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .right {
          flex: 8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 800px;
            transform: rotate(-10deg);
          }
        }
      }
    }
  }
}


// .works{
//     background-color: crimson;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;


//     .arrow{
//         height: 50px;
//         position: absolute;


//         &.left{
//             left: 100px;
//             transform: rotateY(180deg);
//             cursor: pointer;
//         }
//         &.right{
//             right: 100px;
//         }
//     }

//     .slider{
//         height:350px;
//         display: flex;
//         position: absolute;
//         left: 0;


//     .container{
//         width: 100vw;
//         align-items: center;
//         justify-content: center;

//         .item{
//             width: 700px;
//             height: 100%;
//             background-color: white;
//             border-radius: 20px;
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             .left{
//                flex: 4; 
//             //    background-color: aqua;
//                height: 80%;
//                display: flex;
//                align-items: center;
//                justify-content: center;
             


//                .leftContainer{
//                 width: 90%;
//                 height: 70%;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: space-between;
            

//                 .imgContainer{
//                     width: 40px;
//                     height: 40px;
//                     border-radius: 50%;
//                     background-color: coral;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;

//                     img{
//                         width: 25px;
//                     }
//                 }
//                     h2{
//                         font-size: 20px;
//                     }
                

//                     p{
//                         font-size: 13px;
//                     }

//                     span{
//                         font-size: 12px;
//                         font-weight: 600;
//                         text-decoration: underline;
//                         cursor: pointer;
//                     }
                
//                }
//             }

//             .right{
//                 flex: 8;
//                 height: 100%;
          
          
               
//                 // background-color: rgb(179, 199, 49);

//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 overflow: hidden;


//                 img{
//                     width: 400px;
//                     transform:rotate(-10deg);
//                 }
//             }
//         }
//     }
//     }
// }