/* .a {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: white;
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a-right {
  flex: 1;

}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.a-title {
  font-weight: 400;
 
}

.a-role {
  margin: 20px 0px;
  font-weight: 300px;
}

.a-desc{
  margin: 10px 0px;
}

.a-pr{
  margin: 10px 0px;
}
.f{
  margin: 10px 0px;

}

.resume {
  margin-top: 20px;

  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;

    &:hover {
      background-color: #2980b9;
    }
  }
}
@media screen and (max-width:480px) {
    .a{
      flex-direction: column;
      text-align: center;
      margin-top: 50px;
    }

    .a-left {
      width: 100%;
    }

    .a-card {
      height: 30vh;
    }

    .a-card.bg, .a-award {
      display: none;
    }

    .a-right{
      padding: 20px;
    }


 
} */















@import "../../global.scss";


  .a {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: white;

    &-left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
     
      

      .a-card.bg {
        position: absolute;
        top: 50px;
        left: 50px;
        background-color: #333;

      }
      @include mobile{
        .a-card.bg {
          
          top: 20px;
         
        }
      }

      .a-card {
        width: 60%;
        height: 60vh;
        border-radius: 30px;
        position: relative;
        overflow: hidden;

        .a-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .a-title {
        font-weight: 400;
      }

      .a-role {
        margin: 20px 0px;
        font-weight: 300;
      }

      .a-desc {
        margin: 10px 0px;
      }

      .a-pr {
        margin: 10px 0px;
      }

      .f {
        margin: 10px 0px;
      }

     
    }

    &-right {
      flex: 1;

      .resume {
        margin-top: 20px;
        bottom: 20px;
        
      

        .btn {
          display: inline-block;
          padding: 10px 20px;
          background-color: #3498db;
          color: #fff;
          text-decoration: none;
          border-radius: 4px;

          &:hover {
            background-color: #2980b9;
          }
        }
      }
    
    }

  }


@media screen and (max-width: 480px) {
     


    .a {
      flex-direction: column;
      text-align: center;
      margin-top: 10px;
      height: 100vh;

      &-left {
        width: 100%;
      }

      .a-card {
        height: 30vh;
      }

      .a-card.bg, .a-award {
        display: none;
      }
      .a-role {
        margin: 20px 0px;
        font-weight: 100;
      }
      &-right {
        padding: 10px;
      }
    }
  }

