
@import "../../global.scss";

.intro {
  background-color: white;
  background-color: aliceblue;



  display: flex;

  @include mobile{
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.4;
    overflow: hidden;
    // background-color: aqua;

    .imgContainer {
      width: 600px;
      height: 600px;
      // background-color: crimson;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: left;
    

      @include mobile{
        align-items: flex-start;
      }

      img {
        height: 90%;
        object-fit: cover;

        @include mobile{
          height: 50%;
        }
      }
    }
  }
  .right {
    flex: 0.6;
    position: relative;
    // background-color: aquamarine;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile{
        padding-left: 0;
        align-items: center;
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;

        @include mobile{
          font-size: 40px;
        }
      }
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 30px;

        @include mobile{
          font-size: 15px;
        }

        span {
          font-size: inherit;
          color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
      }
    }
    a {
      position: absolute;
      bottom: 140px;
      left: 30%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
































// .intro{
//     background-color: white;
//     display: flex;

//     .left{
//         flex: 0.5;
//         overflow: hidden;
//         // background-color: aqua;

//         .imgContainer{
//             width: 700px;
//             height: 700px;
//             background-color: crimson;
//             border-radius: 50%;
//             display: flex;
//             align-items: flex-end;
//             justify-content: center;
// float: right;
//             img{
//               height: 90%;
//             }
//         }
//     }

//     .right{
//         flex: 0.5;
//         // background-color: aquamarine;
//         position: relative;


//         .wrapper{
//             width: 100%;
//             height: 100%;
//             padding-left: 50px;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;


//             h1{
// font-size: 60px;
// margin: 10px 0;;

//             }

//             h2{
// font-size: 35px;
//             }

//             h3{
// font-size: 30px;
//             }

//             a {
//                 position: absolute;
//                 bottom: 10px;
//                 left: 40%;

//                 img {
//                     width: 30px;
//                     animation: arrowBlink 2s  infinite;
//                 }
//                 @keyframes arrowBlink {
//                     100%{
//                        opacity: 0;
//                     }
//                 }
//             }

//         }
//     }
// }