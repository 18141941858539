
















@import "../../global.scss";

.portfolio {
  // background-color: white;
  background-color: aliceblue;

 
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 50px;

    @include mobile{
      font-size: 20px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }


 
  }

  .container {
    // width: 70%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile{
      width: 100%;
    }

    .item {
      // width: 350px;
      //  height: 250px;
       width: 320px;
      height:180px;
       border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;

      @include mobile{
        width: 130px;
        height: 100px;
      }
      

  a{text-decoration: none;
   position: absolute;

  }
     
      h3 {
        // position: absolute;
       
        font-size: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
        object-fit:cover;
        z-index: 1;
      }


      // video{
      //   width: 100%;
      //   height: 100%;
      //   object-fit: cover;
      //   z-index: 1;
      //   zoom: 1;
      // }


      
     










      &:hover {

        background-color: $mainColor;
        a{
        color: aqua;
        }
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }


      // &:hover {

      //   background-color: $mainColor;
      //   a{
      //   color: aqua;
      //   }
      //   video{
      //     opacity: 0.2;
      //     z-index: 0;
      //   }
      // }





    }
  }
}
