.app{
    height: 100vh;
    .section{
        width:100%;
         height: calc(100vh - 100px);
         background-color: rgb(65, 145, 215);
         position: relative;
         top: 70px;
         scroll-behavior: smooth;
         scroll-snap-type: y mandatory;
         scrollbar-width: none;// for fire base
             &::-webkit-scrollbar{
                display: none;
            }


         > *{
            width: 100%;
            height: calc(100vh - 70px);
             scroll-snap-align: start;
             
         }
    }
}