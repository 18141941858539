// @import "../../global.scss";
.footer {
    background-color: #121111;
    padding: 20px;
    text-align: center;
    height: 30px;
    position: sticky;
    bottom: 0 ;
  }
  
  .footer-content {
    color: #e8e3e3;
  }
  


//  @include mobile{
//   .footer {
   
//     padding: 10px;
   
//     height: 15px;
//     position: sticky;
//     bottom: 0;
//   }
//  }
  
  
